import React, { useState } from 'react';
import BrevoTemplate from './../utils/tempalate';
import useHandleModal from "./useModal1";
import http from "../services/httpService";
import auth from "../services/authService";

const AddEmailCombo = () => {
  const { handleModal2, handleModal3} = useHandleModal();
  const formDatas = {
    email: '',
    name:'',
    remarks:''
  }
  const [formData, setFormData] = useState(formDatas);
  const user = auth.getUser().user;
  const email = user.email;

  const handleSubmit = async () => {
    console.log(formData);
    try {
      await http.post2("/csvUpload5",{userEmail:email,data:{csvData:[formData]},timeStamp:Date.now()})
      .then((res)=>{
        console.log(res, res);
        handleModal3("Email added Succesfully");
        setFormData(formDatas);
        window.location.reload();
      })
      .catch((err)=>{
        console.log(err)
        handleModal2("Data is not Added");
      })
    } catch (error) {
      console.error("Error uploading CSV file:", error);
      alert("Failed Please try again.");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col">
          <label htmlFor="phone" className="form-label">Email:</label>
          <input
            type="email"
            value={formData.email}
            name="email"
            onChange={handleChange}
            className="form-control"
          />
        </div>
        <div className="col">
          <label htmlFor="template" className="form-label">Name:</label>
          <input
            type="text"
            value={formData.name}
            name="name"
            onChange={handleChange}
            className="form-control"
          />
        </div>
        <div className="col">
          <label htmlFor="uPhone" className="form-label">Remarks :</label>
          <input
            type="email"
            value={formData.remarks}
            name="remarks"
            onChange={handleChange}
            className="form-control"
          />
        </div>
        <div className='col'>
            <br></br>
            <button className='btn btn-primary btn-sm mt-2' onClick={handleSubmit}>
                Save
            </button>
        </div>
      </div>
    </div>
  );
};

export default AddEmailCombo;
