const BrevoTemplate = {
    // "Conciliation Notice":2,
    // "Letter of Acceptance":10,
    // "2nd hearing Notice":11,
    // "3rd hearing Notice":13,
    // "Interim Award":14,
    // "Arbitration Award":15,
    "MSME First Email":21
}

export default BrevoTemplate;