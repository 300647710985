import React, { useState } from "react";
import http from "../services/httpService";
import auth from "../services/authService";
import Swal from "sweetalert2";
// import { handleModal, handleModal2} from "./utils";
// import Swal from 'sweetalert2/dist/sweetalert2.js'

// import Swal from 'sweetalert2/dist/sweetalert2.js'
// import 'sweetalert2/src/sweetalert2.scss'
import useHandleModal from "./useModal1";


const AddNumebrCompo = () => {
  const [formData, setFormData] = useState({
    phone: "",
    uPhone: "",
    name: "",
  });
  // const [flag, setFlag]=useState(true);
  const user = auth.getUser().user;
  const { handleModal, handleModal2} = useHandleModal();
  const email = user.email;

  const handleSubmit = async () => {
    console.log(formData);
    handleModal(false);
    try {
      const now = new Date();
      const timeStamp = now.getTime();
      formData.phone="91"+formData.phone;
      formData.uPhone="91"+formData.uPhone;
      
      const response = await http.post2("/csvUpload4", {
        email,
        data: {csvData:[formData]},
        timeStamp,
      });
      console.log(response.data, "response");
      if (response.data === "Data Saved successfully"){
        setFormData({
          phone: "91",
          uPhone: "91",
          name: "",
        });
        handleModal(true);
        window.location.reload();
      } else {
        handleModal2("Failed to save")
      }
    } catch (error) {
      console.error("Error uploading CSV file:", error);
      handleModal2("Failed Please try again.")
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col">
          <label htmlFor="phone" className="form-label">
            Phone:
          </label>
          <input
            type="number"
            value={formData.phone}
            name="phone"
            onChange={handleChange}
            className="form-control"
          />
        </div>
        <div className="col">
          <label htmlFor="uPhone" className="form-label">
            Your Phone:
          </label>
          <input
            type="number"
            value={formData.uPhone}
            name="uPhone"
            onChange={handleChange}
            className="form-control"
          />
        </div>
        <div className="col">
          <label htmlFor="name" className="form-label">
            Name:
          </label>
          <input
            type="text"
            value={formData.name}
            name="name"
            onChange={handleChange}
            className="form-control"
          />
        </div>
        <div className="col">
          <br></br>
          <button
            className="btn btn-primary btn-sm mt-2"
            onClick={handleSubmit}
            disabled={
              formData.phone.length != 10 || formData.uPhone.length != 10
            }
          >
            Save
          </button>
        </div>
      </div>

      {/* <button className="btn btn-primary" onClick={()=>handleModal2("testing")}>
        Modal demo
      </button> */}
    </div>
  );
};

export default AddNumebrCompo;
