import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Navigate, Routes, Route } from "react-router-dom";
import Login from "./components/login";
import auth from "./services/authService";
import CallDashboard from "./components/callDashboard";
import Emailsend from "./components/EmailSend";
import NewUserDashboard from "./components/Dashboard";

function App() {
  const [user, setUser] = useState(auth.getUser().user);

  return (
    <>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path='/dashboard' element={user?<NewUserDashboard/>:<Login/>}/>
        <Route path='/emailModule' element={user?<Emailsend/>:<Login/>}/>
        <Route path='/callModule' element={user?<CallDashboard/>:<Login/>}/>
        <Route path='/' element={<Login/>}/>
      </Routes>
    </>
  );
}

export default App;
