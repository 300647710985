import { useState } from "react";
import ask from "../asset/ask-pic.png";
import bg from "../asset/logo-bg.png";
import auth from "../services/authService";
import http from "../services/httpService";
import { useNavigate  } from 'react-router-dom';
import './style.css'
import useHandleModal from "./useModal1";
// import { handleModal2 } from "./utils";


const Login = () => {
  const [check, setCheck] = useState(false);
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [otpErr, setOtpErr] = useState(false);
  const navigate = useNavigate ();

  const { handleModal, handleModal2} = useHandleModal();

  const handleEmailChange = (e) => {
    const input = e.target.value;
    const lowercaseInput = input.replace(/[A-Z]/g, (char) => char.toLowerCase());
    setEmail(lowercaseInput);
  };

  const handleOtpChange = (e) => {
    setOtpErr(false);
    let inputString=e.target.value;
    inputString= inputString.replace(/[^0-9]/g, "")
    setOtp(inputString);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    handleModal(false);
    try {
      const response = await http.post2('/signin3', { email, otp});
      console.log(response.data);
      auth.login(response.data.token);
      
      handleModal(true);
      navigate('/dashboard');
      window.location.reload();
    } catch (error) {
      setOtpErr(true);
      handleModal2("Please try after some time/Check OTP");
      console.error('Error Sign in:', error);
    }
  };
  
  const handleLoginFormSubmit = async (e) => {
    e.preventDefault();
    handleModal(false);
    try {
      const response = await http.post2('/login3', { email});
      console.log(response.data);
      if(response.data=="undeliverable"){
        handleModal2("Something is wrong, Please check your email");
      }
      else {
        setCheck(true);
        handleModal(true);
      }
 
    } catch (error) {
      
      handleModal2("Something is wrong, Please try latter");
      console.error('Error logging in:', error);
    }
  };

  return (
    <div className="login-wrapper">
      <div className="login-header">
        <div className="container">
          <div className="row align-items-center">
            <div className="col">
              <h1>
                PRIVATE<span >COURT</span>
              </h1>
            </div>
            <div className="col text-end">
             <logo/>
            <img
            src="https://www.pvtcourt.com/images/private-court-symbol.png"
            alt="Logo"
            className=""
            style={{width:'105px', height:'120px'}}
          />
            </div>
          </div>
        </div>
      </div>
      <div className="login-mid">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <h2>Login</h2>
            </div>
            <div className="col-lg-5">
              <div className="card">
                <h1>Login using your <br />Email address</h1>
                <input
                  className="form-control"
                  type="text"
                  placeholder="akshita@gmail.com"
                  value={email}
                  onChange={handleEmailChange}
                />
                {check ? (
                <h6
                  className="text-primary mt-3 mb-3"
                  >
                  The OTP Code has been sent to your email. Please do not forget
                  to check spam if not in inbox.
                </h6>
              ) : (
                ""
              )}
              {check ? (
                <>
                <input
                  placeholder="Enter OTP"
                  type="text"
                  value={otp}
                  onChange={handleOtpChange}
                  className="form-control"
                />
                {otpErr? <h6
                  className="text-primary mt-3 mb-3"
                  >
                  Please enter correct OTP.
                </h6>:""}
                </>
                
                
              ) : (
                ""
              )}
              {check ? (
               
                <div className="text-center d-grid">
                  <button type="submit" className="btn btn-primary" onClick={handleLogin}>Sign In</button>
                </div>
              ) : (
                <div className="text-center d-grid">
                  <button type="submit" className="btn btn-primary" onClick={handleLoginFormSubmit}>Verify Email</button>
                </div>
              )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="ask-person">
          <div className="profile-pic">
            <img src={ask} alt=""/>
          </div>
          <button type="button">
            Ask <span>Murthy</span>
          </button>
      </div> 
      
    </div>
  );
};
export default  Login;

// Pratyush Singh
// 11:05
// connectionGeneration: 14,
//   [Symbol(errorLabels)]: Set(1) { 'ResetPool' }, 
//   [cause]: [Error: B0240000:error:0A000438:SSL routines:ssl3_read_bytes:tlsv1 alert internal error:c:\ws\deps\openssl\openssl\ssl\record\rec_layer_s3.c:1586:SSL alert number 80
//   ] {
//     library: 'SSL routines',
//     reason: 'tlsv1 alert internal error',        
//     code: 'ERR_SSL_TLSV1_ALERT_INTERNAL_ERROR'   
//   }
// }
// Error fetching email data: MongoServerSelectionError: B0240000:error:0A000438:SSL routines:ssl3_read_bytes:tlsv1 alert internal error:c:\ws\deps\openssl\openssl\ssl\record\rec_layer_s3.c:1586:SSL 
// alert number 80