import React, { useEffect, useState, useCallback } from "react";
import http from "../services/httpService";
import auth from "../services/authService";
import Navbar2 from "./navbar2";
import useHandleModal from "./useModal1";

function CallingCompo() {
  const [usersData, setUsersData] = useState([]);
  const [disabledIndex, setDisabledIndex] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [search, setSearch] = useState("");
  // const [flag, setFlag] = useState(false);
  const user = auth.getUser().user;
  const email = user.email;
  const { handleModal, handleModal2, handleModal3 } = useHandleModal();

  const handleCall = async (item, index) => {
    // e.preventDefault();
    setDisabledIndex(index + 1);
    handleModal(false);
    try {
      const response = await http.post2("/connectSelectedNumers", { item });
      console.log(response.data);
      // handleModal(true);
      console.log("hii");
      handleModal3("Call Done");
      setDisabledIndex("");
    } catch (error) {
      handleModal2("something is wrong please check number");
      console.error("Error login in:", error);
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await http.post2("/deleteCallEmailConnect", {
        id,
        database: 1,
      });
      // console.log(response.data);
      // console.log("hii");
      if (response.status === 200) {
        handleModal3("Call deleted");
        window.location.reload();
      }
    } catch (error) {
      handleModal2("Something is wrong please try after sometime");
      console.error("Error login in:", error);
    }
  };

  const handlechange3 = (e) => {
    setSearch(e.target.value);
  };

  const handlePhoneChange = (e, index) => {
    let { value } = e.target;
    value = value.replace(/[^0-9]/g, "");
    const updatedUsersData = [...usersData];
    updatedUsersData[index] = {
      ...updatedUsersData[index],
      uPhone: value,
    };
    setUsersData(updatedUsersData);
  };

  const formatDateFromTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };

  // const fetchData = async () => {
  //   try {
  //     let response = await http.post2(`/getCsvDatasCalling`, {
  //       userEmail: email,
  //       perPage,
  //       page,
  //       search,
  //     });
  //     // console.log(response.data, "res, data");
  //     if (Array.isArray(response.data)) {

  //       setUsersData(response.data);
  //     } else {
  //       setUsersData([]);
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  // useEffect(() => {
  //   fetchData();
  // }, [perPage, page, search]);

  const fetchData = useCallback(async () => {
    try {
      const response = await http.post2(`/getCsvDatasCalling`, {
        userEmail: email,
        perPage,
        page,
        search,
      });
      setUsersData(Array.isArray(response.data) ? response.data : []);
    } catch (err) {
      console.error("Error fetching data:", err);
      setUsersData([]);
    }
  }, [email, perPage, page, search]);

  useEffect(() => {
    console.log("fetchData");
    fetchData();
  }, [fetchData]);

  return (
    <>
      <div className="col-xl-3">
        <div className="form-floating mb-3">
          <input
            type="text"
            className="form-control"
            id="searchInput"
            placeholder="Search By Email, Name or Phone"
            value={search ? search : ""}
            onChange={handlechange3}
          />
          <label htmlFor="searchInput">Search By Email, Name or Phone</label>
        </div>
      </div>
      <div className="col-xl-9">
        {/*  */}
        <>
          <div className="card card-new h-100 mt-3">
            <div className="">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col">RefNo</th>
                    <th scope="col">Date</th>
                    <th scope="col">NBFC</th>
                    <th scope="col">Name</th>
                    <th scope="col">Phone</th>
                    <th scope="col">Your phone</th>
                    <th scope="col">Last Call</th>
                    <th scope="col">Duration</th>
                    <th scope="col">Status</th>
                    <th scope="col">Call</th>
                    <th scope="col">Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {usersData.length > 0
                    ? usersData.map((item, index) => {
                        return (
                          <tr key={item.refNo}>
                            <td data-label="RefNo">{item.refNo}</td>
                            <td data-label="Date">
                              {formatDateFromTimestamp(item.timeStamp)}
                            </td>
                            <td data-label="NBFC">{item.nbfc}</td>
                            <td data-label="Name">{item.name}</td>
                            <td data-label="Phone">{item.phone}</td>
                            <td data-label="Your Phone">
                              {" "}
                              <input
                                type="text"
                                value={item.uPhone}
                                onChange={(e) => handlePhoneChange(e, index)}
                                className="form-control"
                              />
                            </td>
                            <td data-label="Last Call">
                              {item?.prevInfo?.lastCall}
                            </td>
                            <td data-label="Last Call">
                              {item?.prevInfo?.duration}
                            </td>
                            <td data-label="Status">
                              {item?.prevInfo?.status}
                            </td>
                            <td data-label="Call">
                              <button
                                type="button"
                                className="btn btn-primary"
                                // disabled={index == disabledIndex-1}
                                onClick={() => handleCall(item, index)}
                              >
                                <i className="bi bi-phone text-white"></i>
                              </button>
                            </td>
                            <td data-label="Delete">
                              <button
                                type="button"
                                className="btn btn-primary"
                                // disabled={index == disabledIndex-1}
                                onClick={() => handleDelete(item._id)}
                              >
                                <i className="bi bi-trash text-white"></i>
                              </button>
                            </td>
                          </tr>
                        );
                      })
                    : ""}
                </tbody>
              </table>
            </div>
            <div className="row">
              <div className="col-lg-4"></div>
              <div className="col-lg-4">
                <div className="data-pagi float-lg-end mt-lg-0 mt-2">
                  <select
                    className="form-control"
                    id="floatingSelect"
                    value={perPage ? perPage : 10}
                    onChange={(e) => setPerPage(e.target.value)}
                    aria-label="Floating label select example"
                  >
                    <option value={10}>10</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                  </select>
                  <div
                    className="btn-toolbar"
                    role="toolbar"
                    aria-label="Toolbar with button groups"
                  >
                    <div
                      className="btn-group me-1"
                      role="group"
                      aria-label="First group"
                    >
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => setPage(+page - 1)}
                        disabled={page <= 1}
                      >
                        <i className="bi bi-chevron-left text-white"></i>
                      </button>
                    </div>
                    <div
                      className="btn-group me-1"
                      role="group"
                      aria-label="Second group"
                    >
                      <button type="button" className="btn text-primary">
                        {page}
                      </button>
                    </div>
                    <div
                      className="btn-group"
                      role="group"
                      aria-label="Third group"
                    >
                      <button
                        type="button"
                        className="btn btn-primary"
                        disabled={usersData.length < +perPage}
                        onClick={() => setPage(+page + 1)}
                      >
                        <i className="bi bi-chevron-right text-white"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4"></div>
            </div>
          </div>
        </>
        {/*  */}
      </div>
    </>
  );
}

export default CallingCompo;
