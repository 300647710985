import { useEffect, useState } from "react";
import AddEmailCombo from "./addEmailSingle";
import CsvUploader5 from "./csvUplode5";
import BrevoTemplate from "./../utils/tempalate";
import useHandleModal from "./useModal1";
// import timestampToDate from "../utils/getDateTimeStamp";
import http from "../services/httpService";
import auth from "../services/authService";
import Navbar2 from "./navbar2";
import { Link } from "react-router-dom";

const Emailsend = () => {
  const { handleModal2, handleModal3 } = useHandleModal();
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [mainTemplate, setMainTemplate] = useState(0);
  const [flag, setFlag] = useState(false);
  const [flag2, setFlag2] = useState(false);
  const [selectedTemplates, setSelectedTemplates] = useState(
    Array(data.length).fill(0)
  );
  const [selectedEmails, setSelectedEmails] = useState([]);
  const user = auth.getUser().user;
  const email = user.email;

  const getEmailData = async () => {
    await http
      .post2("/getEmailData", {
        userEmail: email,
        perPage,
        page,
        search,
      })
      .then((res) => {
        console.log("data getting");
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDelete = async (id) => {
    try {
      const response = await http.post2("/deleteCallEmailConnect", {
        id,
        database: 2,
      });
      // console.log(response.data);
      // console.log("hii");
      if (response.status === 200) {
        handleModal3("Email deleted");
        window.location.reload();
      }
    } catch (error) {
      handleModal2("Something is wrong please try after sometime");
      console.error("Error login in:", error);
    }
  };

  const handlechange3 = (e) => {
    setSearch(e.target.value);
  };

  const handleTemplateChange = (e, index) => {
    setSelectedTemplates((prevState) => {
      const updatedTemplates = [...prevState];
      updatedTemplates[index] = e.target.value;
      return updatedTemplates;
    });
  };

  const sendEmails = async (template, receiver) => {
    await http
      .post2("/sendNewEmail", {
        template,
        receiver,
        userEmail: email,
      })
      .then((res) => {
        console.log(res);
        handleModal3("Email has been sent");
      })
      .catch((err) => {
        console.log(err);
        handleModal2("Something went wrong");
      });
  };

  const handleSelectAll = () => {
    setSelectAllChecked((prev) => !prev);
    if (!selectAllChecked) {
      setSelectedEmails(data.map((val) => ({ email: val.email })));
    } else {
      setSelectedEmails([]);
    }
  };

  const handleCheckboxChange = (email) => {
    if (selectedEmails.some((item) => item.email === email)) {
      setSelectedEmails(selectedEmails.filter((item) => item.email !== email));
    } else {
      setSelectedEmails([...selectedEmails, { email }]);
    }
  };

  const timestampToDate = (timestamp) => {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };

  const getTemplateName = (templateID) => {
    return Object.keys(BrevoTemplate).find(
      (key) => BrevoTemplate[key] === +templateID
    );
  };

  console.log(mainTemplate);
  useEffect(() => {
    getEmailData();
  }, [perPage, page, search]);
  return (
    <div class="main-wrapper">
      <div className="row">
        <div className="col-3">
          <Navbar2 />
          <Link to="/dashboard" className="ms-2">
            <button
              type="button"
              className="btn btn-light btn-sm back-btn mb-3"
            >
              <i className="bi bi-arrow-left"></i> Back
            </button>
          </Link>
        </div>
        <div className="col-9">
          <div className="m-3">
            <button
              className={flag ? "btn btn-secondary" : "btn btn-primary"}
              onClick={() => setFlag((prev) => !prev)}
            >
              Upload CSV
            </button>
          </div>
          {flag && <CsvUploader5 />}
        </div>
      </div>

      <div class="container-fluid">
        <div class="row">
          <div class="col-xl-3">
            <div class="left-wrapper">
              <h2 class="admin-name"></h2>
              <div class="left-wrapper">
                {/* navbar */}
                <div class="" id="collapseExample">
                  <div class="data-filter">
                    <div class="form-floating mb-3">
                      <input
                        class="form-control"
                        id="floatingSelect"
                        aria-label="Floating label select example"
                        value={search ? search : ""}
                        onChange={handlechange3}
                      />
                      <label>Search By Name, Loan ID, Email</label>
                    </div>
                    {selectAllChecked || selectedEmails.length > 1 ? (
                      <div class="form-floating mb-3">
                        <select
                          type="number"
                          name="template"
                          value={mainTemplate}
                          onChange={(e) => setMainTemplate(e.target.value)}
                          style={{ padding: "0 0 0 8px" }}
                          className="form-select"
                        >
                          <option>Select the Template</option>
                          {BrevoTemplate &&
                            Object.keys(BrevoTemplate).map((temp, index) => {
                              return (
                                <option
                                  value={BrevoTemplate[`${temp}`]}
                                  key={index}
                                >
                                  {temp}
                                </option>
                              );
                            })}
                        </select>
                        <div class="form-floating mb-3 mt-4">
                          <button
                            type="button"
                            class="btn btn-primary"
                            onClick={() => {
                              sendEmails(mainTemplate, selectedEmails);
                            }}
                          >
                            Send Multiple &nbsp;{" "}
                            <i className="bi bi-envelope text-white"></i>
                          </button>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-9">
            <div className="m-3">
              <button
                className={flag2 ? "btn btn-secondary" : "btn btn-primary"}
                onClick={() => setFlag2((prev) => !prev)}
              >
                Add Email
              </button>
            </div>
            {flag2 && <AddEmailCombo />}
            <div class="right-wrapper">
              <div class="conciliation-application-form">
                <form id="regForm" action="#">
                  <div class="col-lg-12 mt-4">
                    <div class="card card-new h-100">
                      <div class="row mb-4"></div>
                      <div class="org-data nbfc-table">
                        <table class="table table-bordered">
                          <thead>
                            <tr>
                              <th scope="col">
                                <input
                                  type="checkbox"
                                  style={{
                                    width: "1.3rem",
                                    height: "1.5rem",
                                    marginLeft: "1rem",
                                  }}
                                  checked={selectAllChecked}
                                  onChange={handleSelectAll}
                                />
                              </th>
                              <th scope="col">Last Date</th>
                              <th scope="col">Loan ID</th>
                              <th scope="col">Name</th>
                              <th scope="col">Email</th>
                              <th scope="col">Template</th>
                              <th scope="col">NBFC</th>
                              <th scope="col">Status</th>
                              <th scope="col">Last Template</th>
                              <th scope="col">Send Email</th>
                              <th scope="col">Delete</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data &&
                              data.map((val, index) => {
                                return (
                                  <tr key={index}>
                                    <td>
                                      <input
                                        type="checkbox"
                                        style={{
                                          width: "1.3rem",
                                          height: "1.5rem",
                                          marginLeft: "1rem",
                                        }}
                                        checked={selectedEmails.some(
                                          (item) => item.email === val.email
                                        )}
                                        onChange={() =>
                                          handleCheckboxChange(val.email)
                                        }
                                      />
                                    </td>
                                    <td data-label="last">
                                      {val?.timeStamp
                                        ? timestampToDate(val.timeStamp)
                                        : "N/A"}
                                    </td>
                                    <td data-label="Loan ID">
                                      {val.loanID ? val.loanID : "N/A"}
                                    </td>
                                    <td data-label="Name">{val?.name}</td>
                                    <td data-label="Email">{val.email}</td>
                                    <td data-label="Select Template">
                                      <select
                                        type="number"
                                        name="template"
                                        value={selectedTemplates[index]}
                                        onChange={(e) =>
                                          handleTemplateChange(e, index)
                                        }
                                        className="form-control"
                                      >
                                        <option>Select the Template</option>
                                        {BrevoTemplate &&
                                          Object.keys(BrevoTemplate).map(
                                            (temp, index) => {
                                              return (
                                                <option
                                                  value={
                                                    BrevoTemplate[`${temp}`]
                                                  }
                                                  key={index}
                                                >
                                                  {temp}
                                                </option>
                                              );
                                            }
                                          )}
                                      </select>
                                    </td>
                                    <td data-label="nbfc">{val?.nbfc}</td>
                                    <td data-label="Status">{val?.Status}</td>
                                    <td data-label="Status">
                                      {getTemplateName(val?.templateID) ?? ""}
                                    </td>
                                    <td data-label="Send Email">
                                      <button
                                        type="button"
                                        class="btn btn-primary"
                                        onClick={() => {
                                          sendEmails(selectedTemplates[index], [
                                            { email: val.email },
                                          ]);
                                        }}
                                      >
                                        <i className="bi bi-envelope text-white"></i>
                                      </button>
                                    </td>
                                    <td data-label="Delete">
                                      <button
                                        type="button"
                                        className="btn btn-primary"
                                        // disabled={index == disabledIndex-1}
                                        onClick={() => handleDelete(val._id)}
                                      >
                                        <i className="bi bi-trash text-white"></i>
                                      </button>
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </form>
                <div className="row">
                  <div className="col-lg-4"></div>
                  <div className="col-lg-4">
                    <div className="data-pagi float-lg-end mt-lg-0 mt-2">
                      <div
                        className="btn-toolbar"
                        role="toolbar"
                        aria-label="Toolbar with button groups"
                      >
                        <select
                          className="form-control"
                          id="floatingSelect"
                          aria-label="Floating label select example"
                          value={perPage ? perPage : 10}
                          onChange={(e) => setPerPage(e.target.value)}
                        >
                          <option value={10}>10</option>
                          <option value={50}>50</option>
                          <option value={100}>100</option>
                        </select>
                        <div
                          className="btn-group me-1"
                          role="group"
                          aria-label="First group"
                        >
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => setPage(+page - 1)}
                            disabled={page <= 1}
                          >
                            <i className="bi bi-chevron-left text-white"></i>
                          </button>
                        </div>
                        <div
                          className="btn-group me-1"
                          role="group"
                          aria-label="Second group"
                        >
                          <button type="button" className="btn text-primary">
                            {page}
                          </button>
                        </div>
                        <div
                          className="btn-group"
                          role="group"
                          aria-label="Third group"
                        >
                          <button
                            type="button"
                            className="btn btn-primary"
                            disabled={data.length < +perPage}
                            onClick={() => setPage(+page + 1)}
                          >
                            <i className="bi bi-chevron-right text-white"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Emailsend;
