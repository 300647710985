import React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import profileIcon from "../asset/icons/user.svg";
import auth from "../services/authService";
import http from "../services/httpService";

function Navbar2() {
  const user = auth.getUser().user;
  const email = user.email;
  const [companyInfo, setCompanyInfo] = useState({});

  const fetchData2 = async () => {
    try {
      const response = await http.post2("/getCompanyLogoInfo", {
        email,
      });
      if (response.status != 400) {
        console.log(response.data);
        setCompanyInfo(response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleLogout=()=>{
    auth.logout();
  }

  useEffect(() => {
    fetchData2();
  }, []);

  return (
    <nav className="navbar top-menu">
      <div className="container-fluid">
        {companyInfo && companyInfo.companyLogoName ? (
          <h1 className="logo">
            {companyInfo.companyLogoName.split(" ")[0].toUpperCase()}
            <span>
              {companyInfo.companyLogoName.split(" ")[1]?.toUpperCase()}
            </span>
          </h1>
        ) : (
          <h1 className="logo">
            PRIVATE<span>COURT</span>
          </h1>
        )}

        {/* {companyInfo.companyLogoName?<h1 className="logo">{companyInfo.companyLogoName.split(" ")[0].toUpperCase()}<span>{companyInfo.companyLogoName.split(" ")[1].toUpperCase()}</span></h1>:<h1 className="logo">PRIVATE<span>COURT</span></h1>} */}
        <div>
          <div className="dropdown">
            <a
              className="dropdown-toggle shadow show"
              href="#"
              data-bs-toggle="dropdown"
              aria-expanded="true"
            >
              {/* <img src={profileIcon} alt='not found'/> */}
              {companyInfo && companyInfo.companyLogoLink ? (
                <img src={companyInfo.companyLogoLink}   style={{ borderRadius: '50%', width: '100%', height: '100%', objectFit: 'cover' }} alt="not found" />
              ) : (
                <img src={profileIcon} alt="not found" />
              )}
              <span className="position-absolute p-2 offline rounded-circle">
                <span className="visually-hidden">New alerts</span>
              </span>
            </a>
            {user.role == "admin" ? (
              <ul
                className="dropdown-menu dropdown-menu-end shadow border-0"
                data-bs-popper="static"
              >
                <li>
                  <Link className="dropdown-item" to="/dashboard">
                    Dashboard
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="/disputes">
                    Cases
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="/salesData">
                    Sales
                  </Link>
                </li>
                <li>
                  <Link
                    className="dropdown-item"
                    to="/dispute-resolution-agreement"
                  >
                    Buy Agreement
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="/usersDashboard">
                    Your Information
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="/ndashboard">
                    New User
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="/ndetailedview">
                    Detailed View
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="/notice">
                    Send Notice
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="/account">
                    Account
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="/adminPanel">
                    Admin Panel
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="/adminUsersPage">
                    Admin Users Page
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="/addSales">
                    Sales Form
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="/leadTable">
                    Lead Table
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="/addMembers">
                    Add Member
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="/logout">
                    Logout
                  </Link>
                </li>
              </ul>
            ) : (
              <ul
                className="dropdown-menu dropdown-menu-end shadow border-0"
                data-bs-popper="static"
              >
              <li>
                  <Link className="dropdown-item" to="/login" onClick={handleLogout}>
                    Logout
                  </Link>
                </li>
              </ul>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar2;
