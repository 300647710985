import React, { useEffect, useState } from "react";
import CsvUploader4 from "./csvUplode4";
import CallingCompo from "./callingCompo";
import AddNumbersCompo from "./addNumbersCompo";
import Navbar2 from "./navbar2";
import { Link } from "react-router-dom";

function CallDashboard() {
  const [upload, setUpload] = useState(false);
  const [flag, setFlag] = useState(true);
  const [addInputs, setAddInputs] = useState(false);
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-3">
            <>
              <Navbar2 />
              <Link to="/dashboard">
                <button
                  type="button"
                  className="btn btn-light btn-sm back-btn mb-3"
                >
                  <i className="bi bi-arrow-left"></i> Back
                </button>
              </Link>
            </>
          </div>
          <div className="col-9">
            <div className="card card-new h-100">
              <div className="m-3">
                <button
                  className={upload ? "btn btn-secondary" : "btn btn-primary"}
                  onClick={() => setUpload((prev) => !prev)}
                >
                  Upload CSV
                </button>
              </div>
              {upload && <CsvUploader4 />}
              <div className="m-3">
                <button
                  className={
                    addInputs ? "btn btn-secondary" : "btn btn-primary"
                  }
                  onClick={() => setAddInputs((prev) => !prev)}
                >
                  Connect Numbers
                </button>
              </div>
              {addInputs && <AddNumbersCompo />}
            </div>
          </div>
        </div>

        {/* <div className="card card-new h-100"> */}
          <div className="row">
            <CallingCompo />
          </div>
        {/* </div> */}
      </div>
    </>
  );
}

export default CallDashboard;
