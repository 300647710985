import React, { useState } from 'react';
import http from '../services/httpService.js';
import Papa from 'papaparse';
import { Button, Container, Form } from 'react-bootstrap';
import auth from '../services/authService.js';
// import { handleModal, handleModal2} from "./utils";
import useHandleModal from "./useModal1";

const CsvUploader = () => {
  const [file, setFile] = useState(null);
  const [data, setData] = useState([]);
  const [flag, setFlag] = useState(false);
  const [sheetName, setSheetName] = useState("");
  const { handleModal, handleModal2, handleModal3} = useHandleModal();

  // cosnt [flag1, setFlag1]=useState(true);
  const user=auth.getUser().user;
  const email=user.email;
  // const handleModal = () => {
  //   let timerInterval;
  //   Swal.fire({
  //     title: "",
  //     html: "",
  //     timerProgressBar: false,
  //     allowOutsideClick: false,
  //     backdrop: true,
  //     didOpen: () => {
  //       Swal.showLoading();
  //       timerInterval = setInterval(() => {
  //         if (flag) {
  //           clearInterval(timerInterval);
  //           Swal.close();
  //         }
  //       }, 100);
  //     },
  //     willClose: () => {
  //       clearInterval(timerInterval);
  //     },
  //   });
  // };

  // const handleModal2 = (str) => {
  //   let timerInterval;
  //   Swal.fire({
  //     icon: "error",
  //     title: "Oops...",
  //     html: str,
  //     didOpen: () => {
  //       timerInterval = setInterval(() => {}, 100);
  //     },
  //     willClose: () => {
  //       clearInterval(timerInterval);
  //     },
  //     customClass: {
  //       popup: 'invisible-modal'
  //     }
  //   }).then((result) => {
  //     if (result.dismiss === Swal.DismissReason.timer) {
  //       console.log("");
  //     }
  //   });
  // };

  const handleFileChange = (event) => {
    const uploadedFile = event.target.files[0];
    setSheetName(uploadedFile.name);
    setFile(uploadedFile);
  };

  const handleSubmit = async () => {
    if (!file) {
      alert("Please select a CSV file.");
      return;
    }
    handleModal(false);
    const formData = new FormData();
    formData.append("csvFile", file);
    try {
       let data1=data.filter(item=>item.phone);
       data1=data1.map(item=>{ 
        if(item.phone.length>=12){
          return item;
        }
        else {
          item.phone='91'+item.phone;
          return item;
        }

      })
      data1=data1.map(item=>{ 
        if(item.uPhone.length>=12){
          return item;
        }
        else {
          item.uPhone='91'+item.uPhone;
          return item;
        }
      })
      const now = new Date();
      const timeStamp = now.getTime();
      const response = await http.post("/csvUpload4", {
        email,
        data: { csvData: data1 },
        timeStamp
      });
      const response2 = await http.post2("/setCsvInfo", {
        data: {
          email,
          timeStamp,
          sheetName:sheetName
        },
      });
      console.log(response.data, "response");
      console.log(response2.data, "response2");
      if (response.data === "Data Saved successfully") {
        handleModal3("Data saved");
        window.location.reload();
        // alert("CSV file uploaded successfully!");
      } else {
        handleModal2("Failed to save data");
      }
    } catch (error) {
      handleModal2("Failed to upload CSV file. Please try again.");
      console.error("Error uploading CSV file:", error);
      // alert("");
    }
  };

  const handleFileRead = (event) => {
    const csvData = event.target.result;
    const parsedData = Papa.parse(csvData, { header: true });
    console.log(parsedData.data, 'parseData');
    setData(parsedData.data);
  };

  const handleFileUpload = () => {
    if (!file) {
      alert('Please select a CSV file.');
      return;
    }
    const reader = new FileReader();
    reader.onloadend = handleFileRead;
    reader.readAsText(file);
  };

  return (
    <div className="container">
    <div className="row">
      <div className="col-md-6 offset-md-3">
        <h4 className="text-center m-4">CSV Uploader</h4>
        <div className="form-group m-2">
          <input
            type="file"
            accept=".csv"
            onChange={handleFileChange}
            style={{ display: 'none' }}
            id="csv-file-input"
          />
          <label htmlFor="csv-file-input" className="btn btn-primary">Select CSV File
            {/* <button   >Select CSV File</button> */}
          </label>
        </div>
        <div className="form-group m-2">
          <button
            className="btn btn-primary "
            disabled={!file}
            onClick={handleFileUpload}
          >
            Upload
          </button>
        </div>
        <div className="form-group m-2">
          <button
            className="btn btn-primary "
            disabled={!(data.length>0)}
            onClick={handleSubmit}
          >
            Submit to MongoDB
          </button>
        </div>
      </div>
    </div>
  </div>
  );
};

export default CsvUploader;
